import axios from 'axios'
import config from '../config'

class ApiV2 {

    static createClient() {
        const token = localStorage.getItem('token');
        const baseUrl = config.api.v2;

        return axios.create({
            baseURL: `${baseUrl}/api/v2/`,
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            }
        });
    }


    static confirmInscription = async (id, inscriptionConfirm) => {
        try {
            const client = ApiV2.createClient();
            const result = await client.post(`/register/inscription/confirm/${id}`, inscriptionConfirm);
            return result
        }
        catch (e) {
            console.error(e);
            return null;
        }
    }
}

export default ApiV2;