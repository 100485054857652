export default {
  discord: {
    clientIdNeto: 1041095368265441340,
    clientId: "808355476634992650",
  },
  api: {
    v2_debug: "https://localhost:7224",
    v2: "https://big-api-center-8447d69b1734.herokuapp.com",
  },
};
