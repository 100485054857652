import { renderTextValue } from '../../components/renderValue'
import React from 'react'
import BaseReadonlyFrame from './BaseReadonlyFrame'
import { injectIntl } from "react-intl"

class FrameReadonlyProfile extends BaseReadonlyFrame {
    constructor(props) {
        super(props)
        this.highlight = props.highlight

        if (!Array.isArray(this.highlight)) 
            this.highlight = []
    }

    render() {
        return (
            <div className="frame-inscriptions">
                <div className="row">
                    {renderTextValue({
                        label: "inscriptions.profile.name",
                        value: this.getValue(this.props.profile?.name),
                        col: 6,
                        col_md: 3,
                        className: this.getClassName("name")
                    })}
                    {renderTextValue({
                        label: "inscriptions.profile.email",
                        value: this.getValue(this.props.profile?.email),
                        col: 6,
                        col_md: 3,
                        className: this.getClassName("email")
                    })}
                    {renderTextValue({
                        label: "inscriptions.profile.phone",
                        value: `+${this.getValue(this.props.profile?.countrycode)}  ${this.getValue(this.props.profile?.phone)}`,
                        col: 6,
                        col_md: 3,
                        className: `${this.getClassName("countrycode")} ${this.getClassName("phone")}`
                    })}
                    {renderTextValue({
                        label: "inscriptions.profile.typecommunication",
                        value: this.getValue(this.props.profile?.typecommunication),
                        col: 6,
                        col_md: 3,
                        className: this.getClassName("typecommunication"),
                        options: [
                            { value: 1, label: "whatsapp" },
                            { value: 2, label: "telegram" },
                            { value: 3, label: "signal" },
                            { value: 4, label: "none" },
                            { value: -1, label: "other" }
                        ]
                    })}
                    {((Array.isArray(this.props.profile?.typecommunication) && this.props.profile?.typecommunication.includes(-1)) 
                        || this.props.profile?.typecommunication === -1)
                        && renderTextValue({
                        label: "inscriptions.profile.othercommunication",
                        value: this.getValue(this.props.profile?.othercommunication),
                        col: 6,
                        col_md: 3,
                        className: this.getClassName("othercommunication")
                    })}
                    
                    {renderTextValue({
                        label: "inscriptions.profile.discord",
                        value: this.getValue(this.props.profile?.discord),
                        col: 6,
                        col_md: 3,
                        className: this.getClassName("discord")
                    })}

                    {renderTextValue({
                        label: "inscriptions.profile.position",
                        value: this.getValue(this.props.profile?.position),
                        col: 6,
                        col_md: 3,
                        className: this.getClassName("position")
                    })}
                </div>
            </div>
        )
    }
}

export default injectIntl(FrameReadonlyProfile)